import Vue from 'vue'
import ElementUI from 'element-ui';
import App from './App.vue'
import router from './router'
import VueMeta from 'vue-meta';
import './assets/tailwind.css'
import '@fortawesome/fontawesome-free/css/all.css'
import 'element-ui/lib/theme-chalk/index.css';

Vue.config.productionTip = false

Vue.use(ElementUI);
Vue.use(VueMeta);

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
