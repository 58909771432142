<template>
    <body class="bg-white">
        <div class="max-w-7xl mx-auto py-3 px-4 sm:px-6 lg:px-8" id="app">
            <header class="flex justify-between items-center py-8">
                <div class="flex items-center">
                    <img alt="Glowe logo" class="w-10 h-10 rounded-full mr-2" src="@/assets/sxlogo.png" width="100"
                        height="100" />
                    <span class="text-3xl font-bold">识心智能</span>
                </div>
                <div>
                    <div v-if="isLoggedIn()" class="relative">
                        <img alt="User avatar" class="w-10 h-10 rounded-full cursor-pointer" height="40"
                            src="@/assets/user.png" width="40" @mouseover="showUserMenu" />
                        <div @mouseleave="hideUserMenu"
                            class="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-lg shadow-lg"
                            v-show="isUserMenuVisible">
                            <a class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" href="#">
                                {{ user.nickname }}
                            </a>
                            <a class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" href="#">
                                {{ user.phone }}
                            </a>
                            <a class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" href="#" @click="logout">
                                退出登录
                            </a>
                        </div>
                    </div>
                    <div v-else class="flex items-center">
                        <button @click="showLogin"
                            class="bg-white text-primary border border-primary px-4 py-2 rounded-full mr-4 hover:bg-primary hover:text-white transition duration-300">登录</button>
                        <button @click="showRegister"
                            class="bg-primary text-white px-4 py-2 rounded-full hover:bg-primary-dark transition duration-300">注册</button>
                    </div>
                </div>
            </header>
            <main @mouseover="hideUserMenu">
                <section class="flex flex-col lg:flex-row justify-between items-center py-12">
                    <div class="text-center lg:text-left">
                        <h1 class="text-3xl lg:text-5xl font-bold mb-6">多模态、多行业</h1>
                        <h1 class="text-3xl lg:text-5xl font-bold mb-12">个性化智能心理健康解决方案</h1>
                        <p class="text-gray-660 mb-8 leading-loose">
                            「识心智能」成立于2024年，致力于为需要心理健康支持的个人和组织，打造一个<b>更理解、更包容、更高效</b>的<b>个性化</b>智能心理健康解决方案。<br />
                            识心智能的愿景是深化AI+心理在教育、医疗、心理咨询与治疗等多领域的深度融合，<br />
                            为<b>各行各业量身定制专属的智能心理健康解决方案</b>，以AI科技的力量促进心理健康事业的创新与繁荣发展。<br />
                        </p>
                        <button @click="beginTrial"
                            class="mt-10 bg-primary text-white px-8 lg:px-36 py-5 rounded-full font-bold hover:bg-primary-dark transition duration-300">开始试用</button>
                    </div>
                </section>
                <section class="py-16">
                    <div class="text-center mb-8">
                        <h1 class="text-4xl font-bold mb-5">“AI心理+” Pro</h1>
                        <h2 class="text-3xl font-bold">多模态 多行业应用场景</h2>
                    </div>
                    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                        <div class="bg-white rounded-lg shadow-lg overflow-hidden">
                            <img alt="A person holding a game controller" class="w-full h-48 object-cover" height="400"
                                src="@/assets/Education.png" width="600" />
                            <div class="p-4">
                                <h3 class="text-xl font-bold mb-2">教育</h3>
                                <p class="text-gray-600">Education</p>
                                <ul class="mt-2 text-gray-600">
                                    <li>智能备课理论助手</li>
                                    <li>教学与学情分析助手</li>
                                    <li>心理赋能教学创新助手</li>
                                    <li>校园心理健康助手</li>
                                    <li>家校社协同沟通助手</li>
                                </ul>
                            </div>
                        </div>
                        <div class="bg-white rounded-lg shadow-lg overflow-hidden">
                            <img alt="A person using a smartphone" class="w-full h-48 object-cover" height="400"
                                src="@/assets/Psych.png" width="600" />
                            <div class="p-4">
                                <h3 class="text-xl font-bold mb-2">心理咨询</h3>
                                <p class="text-gray-600">Psychological Counseling</p>
                                <ul class="mt-2 text-gray-600">
                                    <li>轻度情感疗愈助手</li>
                                    <li>多模态心理健康测评</li>
                                    <li>咨询师助手</li>
                                    <li>AI 智能情绪教练</li>
                                    <li>专属AI心理咨询师</li>
                                </ul>
                            </div>
                        </div>
                        <div class="bg-white rounded-lg shadow-lg overflow-hidden">
                            <img alt="A person typing on a laptop" class="w-full h-48 object-cover" height="400"
                                src="@/assets/Medical.png" width="600" />
                            <div class="p-4">
                                <h3 class="text-xl font-bold mb-2">医疗健康</h3>
                                <p class="text-gray-600">HealthCare</p>
                                <ul class="mt-2 text-gray-600">
                                    <li>医患沟通心理小助手</li>
                                    <li>患者情绪监测</li>
                                    <li>智能康复情绪支持</li>
                                    <li>智能心理诊疗</li>
                                    <li>AI健康顾问</li>
                                </ul>
                            </div>
                        </div>
                        <div class="bg-white rounded-lg shadow-lg overflow-hidden">
                            <img alt="A person writing in a notebook" class="w-full h-48 object-cover" height="400"
                                src="@/assets/Business.png" width="600" />
                            <div class="p-4">
                                <h3 class="text-xl font-bold mb-2">企业管理</h3>
                                <p class="text-gray-600">Business Management</p>
                                <ul class="mt-2 text-gray-600">
                                    <li>AI心理面试官</li>
                                    <li>EAP项目智能助手</li>
                                    <li>职场沟通心理小助手</li>
                                    <li>智能压力管理助手</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <el-dialog :visible.sync="loginForm.dialogFormVisible" :width="dialogWidth" title="登录">
                    <el-form :model="loginForm" label-position="top" ref="loginForm">
                        <el-form-item label="手机号" :error="loginForm.phoneError">
                            <el-input placeholder="请输入手机号" v-model="loginForm.phone" @blur="validateLoginPhone"
                                @input="validateLoginPhone" maxlength="11">
                                <template #prepend="">
                                    <el-select placeholder="+86" disabled style="width: 80px;"
                                        v-model="loginForm.phonePrefix">
                                        <el-option label="+86" value="+86" />
                                    </el-select>
                                </template>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="输入验证码" :error="loginForm.verificationCodeError">
                            <el-input placeholder="请输入验证码" v-model="loginForm.verificationCode"
                                @blur="validateLoginVerificationCode" @input="validateLoginVerificationCode"
                                maxlength="6">
                                <template #append="">
                                    <el-button @click="getVerificationCode('loginForm')"
                                        :disabled="loginForm.isCountingDown" type="primary">{{
                                        loginForm.verificationButtonText }}</el-button>
                                </template>
                            </el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button @click="submitLoginForm" class="w-full bg-primary"
                                type="primary">立即登录</el-button>
                        </el-form-item>
                    </el-form>
                    <p class="text-center mt-4">
                        没有账号？
                        <el-link type="primary" @click="showRegister">立即注册</el-link>
                    </p>
                </el-dialog>
                <el-dialog :visible.sync="form.dialogFormVisible" :width="dialogWidth" title="注册">
                    <el-form :model="form" label-position="top" ref="form">
                        <el-form-item label="手机号" :error="form.phoneError">
                            <el-input placeholder="请输入手机号" v-model="form.phone" @blur="validatePhone"
                                @input="validatePhone" maxlength="11">
                                <template #prepend="">
                                    <el-select placeholder="+86" disabled style="width: 80px;"
                                        v-model="form.phonePrefix">
                                        <el-option label="+86" value="+86" />
                                    </el-select>
                                </template>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="输入验证码" :error="form.verificationCodeError">
                            <el-input placeholder="请输入验证码" v-model="form.verificationCode"
                                @blur="validateVerificationCode" @input="validateVerificationCode" maxlength="6">
                                <template #append="">
                                    <el-button @click="getVerificationCode('form')" :disabled="form.isCountingDown"
                                        type="primary">{{ form.verificationButtonText }}</el-button>
                                </template>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="昵称" :error="form.nicknameError">
                            <el-input placeholder="请输入 20 字符内的昵称" v-model="form.nickname" @blur="validateNickname"
                                @input="validateNickname" maxlength="20"></el-input>
                        </el-form-item>
                        <el-form-item :error="form.agreementError">
                            <el-checkbox v-model="form.agreement">
                                我已阅读并同意
                                <el-link href="#" type="primary">《用户协议》</el-link>
                                与
                                <el-link href="#" type="primary">《隐私政策》</el-link>
                            </el-checkbox>
                        </el-form-item>
                        <el-form-item>
                            <el-button @click="submitForm" class="w-full bg-primary" type="primary">立即注册</el-button>
                        </el-form-item>
                    </el-form>
                    <p class="text-center mt-4">
                        已有账号？
                        <el-link type="primary" @click="showLogin">直接登录</el-link>
                    </p>
                </el-dialog>
            </main>
            <footer class="py-1 border-t">
                <div class="flex justify-center items-center h-screen">
                    <div class="text-center">
                        <img alt="Company Logo" class="mx-auto mb-1" height="100" src="@/assets/sxlogo.png"
                            width="80" />
                        <div class="text-xl font-bold text-gray-800 mb-4">识心智能</div>
                        <div class="flex justify-center space-x-16 mb-8">
                            <div class="text-center">
                                <div class="text-gray-600 mb-4">微信公众号</div>
                                <img alt="WeChat QR Code" class="mx-auto" height="100" src="@/assets/sxgzh.png"
                                    width="100" />
                            </div>
                            <div class="text-center">
                                <div class="text-gray-600 mb-4">视频号</div>
                                <img alt="AiU QR Code" class="mx-auto" height="100" src="@/assets/sxsph.png"
                                    width="100" />
                            </div>
                        </div>
                        <div class="text-gray-600 mb-3">公司名称：北京识心智能科技有限公司</div>
                        <div class="text-gray-600 mb-3">企业邮箱： tech@shixinai.com</div>
                        <div class="text-gray-600 mb-8">Copyright © 2024 北京识心智能科技有限公司 版权所有 | 京ICP备2024091482号-1</div>
                    </div>
                </div>
            </footer>
        </div>
    </body>
</template>

<script>
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

export default {
    name: 'HomePage',
    metaInfo: {
        title: '识心智能 - 首页',
        meta: [
            {
                name: 'description',
                content: '识心智能官网，各行各业量身定制专属的智能心理健康解决方案，以AI科技的力量促进心理健康事业的创新与繁荣发展'
            },
            {
                name: 'keywords',
                content: '识心智能, 心理健康, 心理健康AI, 心理AI, 健康AI, 心理健康方案'
            }
        ]
    },
    data() {
        return {
            isLoggedInState: false,
            isUserMenuVisible: false,
            user: {},
            form: {
                dialogFormVisible: false,
                phone: '',
                phonePrefix: '+86',
                verificationCode: '',
                nickname: '',
                birthday: '',
                gender: '',
                invitationCode: '',
                agreement: false,
                phoneError: '',
                verificationCodeError: '',
                nicknameError: '',
                agreementError: '',
                isCountingDown: false,
                countdown: 60,
                verificationButtonText: '获取验证码'
            },
            loginForm: {
                dialogFormVisible: false,
                phonePrefix: '+86',
                phone: '',
                verificationCode: '',
                phoneError: '',
                verificationCodeError: '',
                isCountingDown: false,
                countdown: 60,
                verificationButtonText: '获取验证码'
            },
            registrationMessage: '',
            loginMessage: '',
            dialogWidth: '30%'
        };
    },
    mounted() {
        this.updateDialogWidth();
        window.addEventListener('resize', this.updateDialogWidth);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateDialogWidth);
    },
    methods: {
        updateDialogWidth() {
            if (window.innerWidth <= 1000) {
                this.dialogWidth = '90%';
            } else {
                this.dialogWidth = '30%';
            }
        },
        isLoggedIn() {
            return !!localStorage.getItem('token');
        },
        showUserMenu() {
            this.isUserMenuVisible = true;
        },
        hideUserMenu() {
            this.isUserMenuVisible = false;
        },
        showRegister() {
            this.loginForm.dialogFormVisible = false;
            this.form.dialogFormVisible = true;
        },
        showLogin() {
            this.form.dialogFormVisible = false;
            this.loginForm.dialogFormVisible = true;
        },
        beginTrial() {
            if (this.isLoggedIn()) {
                // 登录成功后路由到 /chat1 页面
                this.$router.push({ name: 'ChatOne' });
            } else {
                this.form.dialogFormVisible = false;
                this.loginForm.dialogFormVisible = true;
            }
        },
        logout() {
            localStorage.removeItem('token');
            this.user = {};
            this.isLoggedInState = false;
            this.$message({
                message: '登出成功',
                type: 'success'
            });
        },
        validatePhone() {
            const phoneRegex = /^[0-9]{11}$/;
            if (!phoneRegex.test(this.form.phone)) {
                this.form.phoneError = '请输入有效的11位手机号';
            } else {
                this.form.phoneError = '';
            }
        },
        validateVerificationCode() {
            if (!this.form.verificationCode) {
                this.form.verificationCodeError = '验证码不能为空';
            } else if (!/^[0-9]{6}$/.test(this.form.verificationCode)) {
                this.form.verificationCodeError = '请输入6位数字验证码';
            } else {
                this.form.verificationCodeError = '';
            }
        },
        validateNickname() {
            if (!this.form.nickname) {
                this.form.nicknameError = '昵称不能为空';
            } else if (this.form.nickname.length > 20) {
                this.form.nicknameError = '昵称长度不能超过20个字符';
            } else {
                this.form.nicknameError = '';
            }
        },
        validateAgreement() {
            if (!this.form.agreement) {
                this.form.agreementError = '请阅读并同意用户协议和隐私政策';
            } else {
                this.form.agreementError = '';
            }
        },
        saveAuthData(response) {
            // 保存 token 和用户信息到 localStorage
            if (response.data.token) {
                localStorage.setItem('token', response.data.token);
            }
        },
        async submitForm() {
            this.validatePhone();
            this.validateVerificationCode();
            this.validateNickname();
            this.validateAgreement();

            if (this.form.phoneError || this.form.verificationCodeError || this.form.nicknameError || this.form.agreementError) {
                return;
            }

            try {
                const payload = {
                    phone: this.form.phone,
                    nickname: this.form.nickname,
                    birthday: this.form.birthday,
                    gender: this.form.gender,
                    verificationCode: this.form.verificationCode
                };
                const response = await axios.post('/api/register', payload, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                this.$message({
                    message: response.data.message,
                    type: response.data.type
                });
                this.registrationMessage = response.data.message;
                this.form.dialogFormVisible = false;
                if (response.data.token) {
                    this.saveAuthData(response);
                    // 查询用户信息
                    await this.checkTokenAndFetchUserInfo();
                }
            } catch (error) {
                console.error('Registration failed:', error);
                this.registrationMessage = 'Registration failed. Please try again.';
            }
        },
        validateLoginPhone() {
            const phoneRegex = /^[0-9]{11}$/;
            if (!phoneRegex.test(this.loginForm.phone)) {
                this.loginForm.phoneError = '请输入有效的11位手机号';
            } else {
                this.loginForm.phoneError = '';
            }
        },
        validateLoginVerificationCode() {
            if (!this.loginForm.verificationCode) {
                this.loginForm.verificationCodeError = '验证码不能为空';
            } else if (!/^[0-9]{6}$/.test(this.loginForm.verificationCode)) {
                this.loginForm.verificationCodeError = '请输入6位数字验证码';
            } else {
                this.loginForm.verificationCodeError = '';
            }
        },
        async submitLoginForm() {
            this.validateLoginPhone();
            this.validateLoginVerificationCode();

            if (this.loginForm.phoneError || this.loginForm.verificationCodeError) {
                return;
            }

            try {
                const payload = {
                    phone: this.loginForm.phone,
                    verificationCode: this.loginForm.verificationCode
                };
                const response = await axios.post('/api/login', payload, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                this.$message({
                    message: response.data.message,
                    type: response.data.type
                });
                console.log('Login successful:', response.data);
                this.loginMessage = response.data.message;
                this.loginForm.dialogFormVisible = false;
                if (response.data.token) {
                    this.saveAuthData(response);
                    // 查询用户信息
                    await this.checkTokenAndFetchUserInfo();
                }
            } catch (error) {
                console.error('Login failed:', error);
                this.loginMessage = 'Login failed. Please try again.';
            }
        },
        async getVerificationCode(formType) {
            const form = this[formType];
            this.validatePhone();
            if (form.phoneError) {
                this.$message({
                    message: form.phoneError,
                    type: 'error'
                });
                return;
            }

            try {
                const response = await axios.post('/api/get_verification_code', {
                    phone: form.phone,
                    type: formType === 'form' ? 'register' : 'login'
                }, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                console.log('Verification code sent:', response.data);
                this.$message({
                    message: response.data.message,
                    type: response.data.type
                });
                this.startCountdown(formType);
            } catch (error) {
                console.error('Failed to get verification code:', error);
                this.$message({
                    message: '获取验证码失败，请重试',
                    type: 'error'
                });
            }
        },
        startCountdown(formType) {
            const form = this[formType];
            form.isCountingDown = true;
            form.countdown = 60;
            form.verificationButtonText = `${form.countdown}s`;
            const interval = setInterval(() => {
                form.countdown -= 1;
                if (form.countdown > 0) {
                    form.verificationButtonText = `${form.countdown}s`;
                } else {
                    clearInterval(interval);
                    form.verificationButtonText = '获取验证码';
                    form.isCountingDown = false;
                }
            }, 1000);
        },
        async checkTokenAndFetchUserInfo() {
            const token = localStorage.getItem('token');
            if (token) {
                try {
                    const decodedToken = jwtDecode(token);
                    const currentTime = Date.now() / 1000; // 当前时间的秒数
                    // const expDate = new Date(decodedToken.exp * 1000).toLocaleString();
                    // const currentDate = new Date(currentTime * 1000).toLocaleString();
                    // console.log('Token decoded.exp:', expDate, 'currentTime:', currentDate);

                    if (decodedToken.exp > currentTime) {
                        // Token 未过期，查询用户信息
                        const response = await axios.get(`/api/users/${decodedToken.user_id}`, {
                            headers: {
                                'Authorization': `Bearer ${token}`
                            }
                        });
                        this.user = response.data;
                        this.isLoggedInState = true;
                    } else {
                        // Token 已过期，清除本地存储
                        localStorage.removeItem('token');
                    }
                } catch (error) {
                    console.error('Token 解析失败或用户信息查询失败:', error);
                    localStorage.removeItem('token');
                }
            }
        },
        async updateToken() {
            const token = localStorage.getItem('token');
            if (token) {
                try {
                    const response = await axios.post('/api/refresh_token', {}, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    const newToken = response.data.token;
                    localStorage.setItem('token', newToken);
                } catch (error) {
                    console.error('Failed to update token:', error);
                }
            }
        },
    },
    created() {
        this.checkTokenAndFetchUserInfo();
        this.updateToken();
    }
};
</script>

<style>
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@400;500;700&display=swap');
@import url('https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css');

body {
    font-family: 'Noto Sans SC', sans-serif;
}
</style>
