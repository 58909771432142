<template>
    <iframe
     src="https://dify.twirlingai.com/chatbot/SGvZ6mcQgzjlWcZ2"
     style="width: 100%; height: 100%; min-height: 700px"
     frameborder="0"
     allow="microphone">
    </iframe>
    </template>
    
    <script>
    import axios from 'axios';
    import { jwtDecode } from 'jwt-decode';
    
    export default {
        name: 'ChatOne',
        data() {
            return {
                user: {},
            };
        },
        methods: {
            async checkTokenAndFetchUserInfo() {
                const token = localStorage.getItem('token');
                if (token) {
                    try {
                        const decodedToken = jwtDecode(token);
                        const currentTime = Date.now() / 1000; // 当前时间的秒数
                        // const expDate = new Date(decodedToken.exp * 1000).toLocaleString();
                        // const currentDate = new Date(currentTime * 1000).toLocaleString();
                        // console.log('Token decoded.exp:', expDate, 'currentTime:', currentDate);
    
                        if (decodedToken.exp > currentTime) {
                            // Token 未过期，查询用户信息
                            const response = await axios.get(`/api/users/${decodedToken.user_id}`, {
                                headers: {
                                    'Authorization': `Bearer ${token}`
                                }
                            });
                            this.user = response.data;
                        } else {
                            // Token 已过期，清除本地存储
                            localStorage.removeItem('token');
                            // 首页跳转
                            this.$router.push('/');
                        }
                    } catch (error) {
                        console.error('Token 解析失败或用户信息查询失败:', error);
                        localStorage.removeItem('token');
                        this.$router.push('/');
                    }
                } else {
                    // 如果没有 token，跳转到首页
                    this.$router.push('/');
                }
            },
            async updateToken() {
                const token = localStorage.getItem('token');
                if (token) {
                    try {
                        const response = await axios.post('/api/refresh_token', {}, {
                            headers: {
                                'Authorization': `Bearer ${token}`
                            }
                        });
                        const newToken = response.data.token;
                        localStorage.setItem('token', newToken);
                    } catch (error) {
                        console.error('Failed to update token:', error);
                    }
                }
            },
        },
        created() {
            this.checkTokenAndFetchUserInfo();
            this.updateToken();
        }
    };
    </script>
