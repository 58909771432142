import Vue from 'vue'
import Router from 'vue-router'
import HomePage from '../components/HomePage.vue'
import ChatOne from '../components/ChatOne.vue'

Vue.use(Router)
export default new Router({
  routes: [
    {
      path: '/',
      name: 'HomePage',
      component: HomePage
    },
    {
      path: '/chat1',
      name: 'ChatOne',
      component: ChatOne
    }
  ]
})